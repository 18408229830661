import XHR from '@/request/http'
const $h = new XHR()
// url
const url = {
    all:'/admin/v1/dept/list',
    lists:'/admin/v1/dept/build',
    create:'/admin/v1/dept/create',
    update:'/admin/v1/dept/update',
    del:'/admin/v1/dept/del',
    
}

//角色拥有列表
export async function lists(data)
{
    return await $h.request(url.lists,data,'GET')
}

//所有
export async function all(data)
{
    return $h.request(url.all,data,'GET')
}

//create
export async function create(data)
{
    return $h.request(url.create,data,'POST')
}

//更新
export async function update(data)
{
    return $h.request(url.update,data,'POST')
}

//删除
export async function del(data)
{
    return $h.request(url.del,data,'POST')
}


