import XHR from '@/request/http'
const $h = new XHR()
// url
const url = {
    lists:'/admin/v1/users/page',
    create:'/admin/v1/users/create',
    update:'/admin/v1/users/update',
    del:'/admin/v1/users/del',
    get_user:'/admin/v1/users/get',
    export:'/admin/v1/users/export',
    import:'/admin/v1/users/import',
    reset:'/admin/v1/users/resetPwd',
    edit_pwd:'/admin/v1/users/updatePwd',
    lock:'/admin/v1/users/lock',
    unlock:'/admin/v1/users/unlock',
    template:'/admin/v1/users/template',
    resetPassword:'/admin/v1/users/updatePwd', //修改密码
    ststoken:'/admin/v1/file/stsToken',
}

//用户列表
export async function lists(data)
{
    return await $h.request(url.lists,data,'GET')
}

export async function create(data)
{
    return await $h.request(url.create,data,'POST')
}

export async function update(data)
{
    return await $h.request(url.update,data,'POST')
}

export async function del(data)
{
    return await $h.request(url.del,data,'POST')
}

export async function get_user(id)
{
    return await $h.request(url.get_user,{id:id},'GET')
}
export async function exports(data)
{
    return await $h.request(url.export,data,'POST')
}

export async function imports(data)
{
    return await $h.request(url.import,data,'POST')
}

export async function reset(data)
{
    return await $h.request(url.reset,data,'POST')
}

export async function edit_pwd(data)
{
    return await $h.request(url.edit_pwd,data,'POST')
}

export async function lock(data)
{
    return await $h.request(url.lock,data,'POST')
}

export async function unlock(data)
{
    return await $h.request(url.unlock,data,'POST')
}
export async function resetPassword(data)
{
    return await $h.request(url.resetPassword,data,'POST')
}

export async function template(data)
{
    return await $h.request(url.template,data,'GET')
}

export async function getStstoken(data){
    return $h.request(url.ststoken,data,'GET')
}

